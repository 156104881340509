// 职位
<template>
  <div class="position">
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 职位管理配置
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <h3>职位管理配置</h3>
      <div class="handle-box">
        <span>条件:</span>
        <el-input v-model="query.name"
                  placeholder="输入职位ID、职位名称"
                  class="handle-input mr10 w100"></el-input>
        <span>职业:</span>
        <el-select v-model="fatherId"
                   placeholder="请选择"
                   class="handle-select mr10">
          <el-option v-for="(item) in  fatherList"
                     :key="item.id"
                     :label="item.name"
                     :value="item.id"></el-option>
        </el-select>

        <el-button type="primary ml10"
                   icon="el-icon-search"
                   @click="handleSearch">搜索</el-button>
      </div>
      <el-table :data="tableData"
                border
                class="table"
                ref="multipleTable"
                header-cell-class-name="table-header"
                @selection-change="handleSelectionChange">
        <el-table-column prop="id"
                         label="级别ID"
                         width="120"
                         align="center">
          <template #default="scope">
            <span> {{ scope.row.id }}</span>
          </template>

        </el-table-column>
        <el-table-column prop="id"
                         label="职位名称"
                         align="center">

          <template #default="scope">
            <span v-if="scope.row && scope.row .writeFlag">
              <el-input size="mini"
                        placeholder="中英文字符"
                        maxlength="10"
                        v-model="scope.row.name">
              </el-input>
            </span>
            <span v-else> {{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="id"
                         label="所属职业"
                         align="center">
          <template #default="scope">
            {{showTags(i,scope.row) }}
          </template>
        </el-table-column>
        <el-table-column prop="id"
                         label="背景图片"
                         width="300"
                         align="center">
          <template #default="scope">
            <el-image class="pre"
                      :src="scope.row.backGround"
                      :preview-src-list="[scope.row.backGround]"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="describe"
                         label="职位介绍"
                         align="center">
          <template #default="scope">
            {{scope.row.describe && scope.row.describe.slice(0,50)}}
          </template>
        </el-table-column>
        <el-table-column prop="route"
                         label="路线介绍"
                         align="center">
          <template #default="scope">
            {{scope.row.route && scope.row.route.slice(0,50)}}
          </template>
        </el-table-column>

        <el-table-column label="操作"
                         width="360"
                         align="center">
          <template #default="scope">
            <el-button type="text"
                       icon="el-icon-edit"
                       @click="handleEdit(scope.$index, scope.row)">修改</el-button>
            <el-button type="text"
                       icon="el-icon-circle-close"
                       v-if="scope.row.isEnable"
                       class="red"
                       @click="changeStatus(scope.row)">禁用</el-button>
            <el-button type="text"
                       icon="el-icon-circle-check"
                       v-else
                       @click="changeStatus( scope.row)">启用</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="add-title"
           @click="addGoods">
        +添加职位
      </div>
      <!-- 分页器 -->
      <div class="page"
           v-if="tableData.length > 0">
        <el-pagination background
                       layout="total, prev, pager, next"
                       :current-page="pageIndex"
                       :page-size="20"
                       :total="pageCount"
                       @current-change="handlePageChange"></el-pagination>

      </div>

      <el-dialog v-model="showDialog"
                 :title="showTitle"
                 width="40%"
                 center>
        <el-form ref="formData"
                 label-width="120px">
          <el-form-item label="职位名称:"
                        required>
            <el-input v-model.trim="occName"
                      placeholder="中英文限20字，必填"
                      maxlength="20"></el-input>
          </el-form-item>
          <el-form-item label="所属职业:"
                        required>
            <span v-for="(tag,i) in tags"
                  @close="closeTag(i)"
                  class="tag"
                  :key="tag.name">{{tag.name }};</span>
            <el-button type="primary"
                       @click="openChageTag">选择</el-button>

          </el-form-item>
          <!-- 背景图片开始 -->
          <el-form-item label="宣传图片:"
                        required>
            <el-upload class="up-btn"
                       :http-request="uploadCover"
                       action="String"
                       :beforeUpload="beforeRemove"
                       multiple
                       :show-file-list="false">
              <el-button type="primary">点击上传</el-button>
            </el-upload>
            <el-alert title="图片大小限制在1M以内，数量限制1张，必填"
                      type="warning"
                      :closable="false">
            </el-alert>
            <div class="person-img">
              <el-image class="pre"
                        :src="backGround"
                        :preview-src-list="[backGround]"></el-image>
            </div>

          </el-form-item>

          <!-- 背景图片结束 -->

          <el-form-item label="职位介绍:"
                        required>
            <el-input v-model.trim="describe"
                      type="textarea"
                      show-word-limit
                      placeholder="中英文限300字，必填"
                      maxlength="300"></el-input>
          </el-form-item>
          <el-form-item label="路线介绍:"
                        required>
            <el-input v-model.trim="route"
                      type="textarea"
                      show-word-limit
                      placeholder="中英文限300字，必填"
                      maxlength="300"></el-input>
          </el-form-item>

        </el-form>

        <template #footer>
          <span class="dialog-footer">
            <el-button @click="saveDialog">保存</el-button>
            <el-button type="primary"
                       @click="showDialog = false">取消</el-button>
          </span>
        </template>
      </el-dialog>

      <!-- 选职业 -->
      <el-dialog v-model="changTag"
                 class="change-dialog"
                 title="选择职业"
                 width="40%"
                 center>
        <ul class="occList">
          <li v-for="(item ,i) in occList "
              @click="addOcc(item)"
              :key="i">{{item.name }} </li>
        </ul>
        <div v-if="tags.length > 0 "> <span>已选中数据：</span>
          <el-tag v-for="(tag,i) in tags"
                  @close="closeTag(i)"
                  class="tag"
                  :key="tag.name"
                  closable>
            {{ tag.name  }}
          </el-tag>
        </div>
        <div v-else>
          <span>未选中数据</span>
        </div>
        <div class="change-btn">
          <el-button @click="noChange">取消 </el-button>
          <el-button type="primary"
                     @click="okChange">选好了</el-button>
        </div>

      </el-dialog>

    </div>

  </div>
</template>

<script>
import { getPositionListTwo, savePayList, getAllPositionTwo, getPostitionUpdate, getPositionStatus, getUploadMh } from "../../api/index";
var md5 = require("@/assets/js/md5.min.js");

export default {
  name: "basetable",
  data () {
    return {
      occId: '',
      occName: '',
      pageCount: 0,
      pageIndex: 1,// 当前页
      query: {
        address: "",
        name: "",
        pageIndex: 1,
        pageSize: 10
      },
      tableData: [],
      multipleSelection: [],
      delList: [],
      editVisible: false,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1,
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      writeFlag: false,// 行编辑状态
      showDialog: false,
      fatherId: '',
      fatherList: [{
        name: " 职位id",
        id: 1
      }, {
        name: " 职位id2",
        id: 2
      },
      ],
      occList: [],
      tags: [],
      showTitle: "添加职位",
      backGround: "",
      changTag: false,
      route: "",
      describe: "",
      pic: ""

    };
  },
  created () {
    this.getData();
    this.getOptions();
    this.getAllOcc();
  },
  methods: {
    openChageTag () {
      this.changTag = true;

    },
    saveDialog () {
      if (this.occName.length <= 0) {
        this.$message.warning("请输入职位名称");
        return false;
      }
      let occuIds = [];
      if (this.tags.length <= 0) {
        this.$message.warning("请选择职业标签");
        return false;
      }
      if (this.backGround.length <= 0) {
        this.$message.warning("请上传封面图");
        return false;
      }
      if (this.describe.length <= 0) {
        this.$message.warning("请填写职位介绍");
        return false;
      } else if (this.describe.length > 300) {
        this.$message.warning("职位介绍，字数限制在300以内");
        return false;
      }
      if (this.route.length <= 0) {
        this.$message.warning("请填写职业路线");
        return false;
      } else if (this.route.length > 300) {
        this.$message.warning("职业路线，字数限制在300以内");
        return false;
      }


      this.tags.map(item => {
        occuIds.push(item.id);
      });
      occuIds = occuIds.join(",");
      let params = {
        id: this.occId,     //id 为空则新增，不为空则修改
        name: this.occName,     //职业名称
        occuIds: occuIds,   //职业id，集合
        describe: this.describe,
        route: this.route,
        domain: "https://pptres.qyzhuomian.com",
        pic: this.pic,



      };
      getPostitionUpdate(params).then(res => {
        if (res.code == 0) {
          console.log(1);
          this.getData();
          this.showDialog = false;
          if (this.occuId) {
            this.$notify.success({
              title: "提示",
              message: "数据保存成功"
            });
          } else {
            this.$notify.success({
              title: "提示",
              message: "数据保存成功"
            });
          }
        }
      })
    },
    // 添加
    addOcc (v) {

      let len = this.tags.filter(item => {
        return item.id == v.id;
      })
      if (len.length > 0) {
        this.$notify.warning({
          title: "提示",
          message: "数据已经存在，请重新选择"
        });
      } else {
        this.tags.push(v)
      }

    },
    // 删除tag
    closeTag (i) {
      this.tags.splice(i, 1);
    },
    getAllOcc () {
      getAllPositionTwo().then(res => {
        let list = res.data.filter(item => {
          return item.isEnable == true
        })
        this.occList = list;
      })
    },
    getOptions () {
      getAllPositionTwo().then(res => {
        this.fatherList = res.data;
        this.fatherList.unshift({ name: "不限", id: "" })
      })
    },
    changeStatus (row) {
      let str = row.isEnable ? "禁用" : "启用";
      this.$confirm("确定要" + str + "吗？", "提示", {
        type: "warning"
      })
        .then(() => {
          let params = {
            id: row.id
          }
          getPositionStatus(params).then(res => {
            console.log(res);
            this.getData();
            this.$notify.success({
              title: "提示",
              message: "操作成功"
            });

          });
        })
        .catch(() => { });
    },
    // 获取列表
    getData () {
      let params = {
        "name": this.query.name,     //数字则查id，文字则查名称
        occuId: this.fatherId,
        "pageIndex": this.query.pageIndex,
        "pageSize": 20
      };
      getPositionListTwo(params).then(res => {
        console.log(res);
        if (res.code == 0) {
          this.tableData = res.data.data;
          this.pageCount = res.data.total

        }
      })
    },
    // 保存列表
    savePayData () {
      this.tableData.map(item => {
        let n = String(item.price).indexOf(".");
        if (n > -1) {
          item.price = String(item.price).substr(0, n) + String(item.price).substr(n, 3);

        }
        let m = String(item.rawPrice).indexOf(".");
        if (m > -1) {
          item.rawPrice = String(item.rawPrice).substr(0, m) + String(item.rawPrice).substr(m, 3);
        }
      })

      let normal = this.tableData[2];
      let gold = this.tableData[0];
      let lifeLong = this.tableData[1];
      let priceList = this.tableData.filter(item => {
        return Number(item.price) < 0.01 || Number(item.price) > 9999 || item.price == ""
      });
      if (priceList.length > 0) {
        this.$notify.warning({
          title: "提示",
          message: "实付价格范围在0.01~9999之间,不能为空"
        });
        return false;
      }

      let rawPriceList = this.tableData.filter(item => {
        return Number(item.rawPrice) < 0.01 || Number(item.rawPrice) > 9999 || item.rawPrice == ""
      })
      console.log(rawPriceList);
      if (rawPriceList.length > 0) {
        this.$notify.warning({
          title: "提示",
          message: "原始价格范围在0.01~9999之间"
        });
        return false;

      }

      let downCount = this.tableData.filter(item => {
        console.log('====================================');
        console.log(Number(item.downloadTime) < 0, item.downloadTime === "");
        console.log('====================================');
        return Number(item.downloadTime) < 0 || item.downloadTime === ""
      })
      if (downCount.length > 0) {
        this.$notify.warning({
          title: "提示",
          message: "下载总次数大于等于0,不能为空"
        });
        return false;

      }

      let pptDownCount = this.tableData.filter(item => {
        return Number(item.pptDailyTime) < 0 || item.pptDailyTime === ""
      })
      if (pptDownCount.length > 0) {
        this.$notify.warning({
          title: "提示",
          message: "PPT次数大于等于0，不能为空"
        });
        return false;

      }

      let wordDownCount = this.tableData.filter(item => {
        return Number(item.wordDailyTime) < 0 || item.wordDailyTime === ""
      })
      if (wordDownCount.length > 0) {
        this.$notify.warning({
          title: "提示",
          message: "Word次数大于等于0,不能为空"
        });
        return false;

      }

      let excelDownCount = this.tableData.filter(item => {
        return Number(item.excelDailyTime) < 0 || item.excelDailyTime === ""
      })
      if (excelDownCount.length > 0) {
        this.$notify.warning({
          title: "提示",
          message: "Excel次数大于等于0,不能为空"
        });
        return false;

      }
      let sortList = this.tableData.filter(item => {
        return item.sort === "" || Number(item.sort) < 0 || item.name.length <= 0
      })

      if (sortList.length > 0) {
        this.$notify.warning({
          title: "提示",
          message: "排序和套餐名称为必填项"
        });
        return false;
      } else {
        this.tableData.map(item => {
          item.writeFlag = false;
        })
      }

      let goldStatus = String(gold.enable).replace(String(gold.enable)[0], String(gold.enable)[0].toUpperCase());
      let lifeLongStatus = String(lifeLong.enable).replace(String(lifeLong.enable)[0], String(lifeLong.enable)[0].toUpperCase());
      let normalStatus = String(normal.enable).replace(String(normal.enable)[0], String(normal.enable)[0].toUpperCase());


      let str = "1bca15648633405ab86330d0d97aceb6" + gold.downloadTime + goldStatus +
        gold.excelDailyTime + gold.memberType + gold.name + gold.packageName + gold.pptDailyTime + gold.price + gold.rawPrice + gold.sort +
        gold.wordDailyTime + lifeLong.downloadTime + lifeLongStatus + lifeLong.excelDailyTime + lifeLong.memberType + lifeLong.name +
        lifeLong.packageName + lifeLong.pptDailyTime + lifeLong.price + lifeLong.rawPrice + lifeLong.sort + lifeLong.wordDailyTime +
        normal.downloadTime + normalStatus + normal.excelDailyTime + normal.memberType + normal.name + normal.packageName + normal.pptDailyTime +
        normal.price + normal.rawPrice + normal.sort + normal.wordDailyTime;

      let sign = md5(str).toUpperCase();


      let params = {
        normal,
        gold,
        lifeLong,
        sign
      }
      savePayList(params).then(res => {
        console.log('====================================');
        console.log(res);
        console.log('====================================');
        if (res.code === 0) {
          this.$notify.success({
            title: "提示",
            message: "保存成功"
          });
        } else {
          this.$notify.error({
            title: "提示",
            message: res.message
          });
        }
      })
    },
    checkNumTwo (e) {
      // 通过正则过滤小数点后两位
      console.log(e);
      e.target.value = (e.target.value.match(/^\d*(\.?\d{0,2})/g)[0]) || null;

    },

    //禁用
    DisableRow () {
      this.$confirm('将禁用该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '禁用成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消禁用'
        });
      });
    },
    // 启用
    enableRow () {
      this.$confirm('将启用该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '启用成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消启用'
        });
      });
    },
    //删除
    delRow () {
      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },


    handleRemove (file) {
      console.log(file);
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload (file) {
      console.log(file);
    },

    // 触发搜索按钮
    handleSearch () {
      this.query.pageIndex = 1;
      this.getData();
    },
    // 删除操作
    handleDelete (index) {
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning"
      })
        .then(() => {
          this.$message.success("删除成功");
          this.tableData.splice(index, 1);
        })
        .catch(() => { });
    },
    // 多选操作
    handleSelectionChange (val) {
      this.multipleSelection = val;
    },
    delAllSelection () {
      const length = this.multipleSelection.length;
      let str = "";
      this.delList = this.delList.concat(this.multipleSelection);
      for (let i = 0; i < length; i++) {
        str += this.multipleSelection[i].name + " ";
      }
      this.$message.error(`删除了${str}`);
      this.multipleSelection = [];
    },
    // 编辑操作
    handleEdit (index, row) {
      this.showTitle = "修改职位";
      console.log(index, row);
      let backGround = row.backGround;
      let str1 = backGround.indexOf(".com") + 4;
      if (str1 > 4) {
        let imgUrl = String(backGround).substring(str1);
        this.pic = imgUrl;
      } else {
        this.pic = "";
      }


      let ids = row.occuIds.split(',');
      let newTags = [];
      this.occList.map(item => {
        ids.map(c => {
          if (c == item.id) {
            newTags.push(item)
          }
        })
      });
      this.occName = row.name;
      this.occId = row.id;
      this.describe = row.describe;
      this.route = row.route;
      this.backGround = row.backGround;
      this.tags = newTags;
      this.oldTags = this.tags.slice(0);

      console.log(this.tags, 999);
      this.showDialog = true;
    },
    showTags (index, row) {

      console.log(index, row);
      let ids = row.occuIds.split(',');
      let newTags = [];
      this.occList.map(item => {
        ids.map(c => {
          if (c == item.id) {
            newTags.push(item)
          }
        })
      });
      this.occName = row.name;
      this.occId = row.id;
      let list = [];
      newTags.map(v =>
        list.push(v.name)
      )
      list = list.join(",");
      return list;


    },
    addGoods () {
      this.occName = "";// 清空 
      this.describe = "";
      this.route = "";
      this.backGround = "";
      this.pic = "";

      this.showTitle = "添加职位";
      this.occId = '';
      this.tags = [];
      this.showDialog = true;

    },
    handleSave (index, row) {
      console.log(row);
      // row.writeFlag = false;
      this.savePayData();
    },
    // 保存编辑
    saveEdit () {

      this.$message.success(`修改第 ${this.idx + 1} 行成功`);
      this.$set(this.tableData, this.idx, this.form);
    },
    // 分页导航
    handlePageChange (val) {
      this.query.pageIndex = val;
      this.getData();
    },
    // 上传封面
    uploadCover (params) {
      const file = params.file;
      console.log(params, file, "888");
      var formdata = new FormData();
      formdata.append("file", file);
      getUploadMh(formdata).then(res => {
        console.log(res);
        this.backGround = res.data.url;
        this.pic = res.data.path;
      })
    },
    // 上传图片校验
    beforeRemove (file, fileList) {
      console.log(fileList);
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'jpg' || testmsg === 'JPG' || testmsg === 'png' || testmsg === 'PNG';
      const isLt50M = file.size / 1024 / 1024 < 1;
      if (!extension) {
        this.$message({
          message: '上传文件只能是jpg或者png格式!',
          type: 'error'
        });
        return false;//必须加上return false; 才能阻止
      }
      console.log(file)
      if (!isLt50M) {
        this.$message({
          message: '上传文件大小不能超过 1MB!',
          type: 'error'
        });
        return false;
      }
      return extension && isLt50M;
    },
    // 取消
    noChange () {
      this.changTag = false;
      this.tags = this.oldTags;

    },
    okChange () {
      this.changTag = false;


    },


  }
}
</script>

<style scoped lang='scss'>
.handle-box {
  margin-top: 8px;
  margin-bottom: 8px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
.container {
  position: relative;
  top: 0;
  left: 0;
}
.add-title {
  margin-top: 8px;
  width: 100%;
  line-height: 40px;
  border-radius: 4px;
  // border: 1px solid #409eff;
  background: #409eff;
  text-align: center;
  cursor: pointer;
  color: #fff;
}
.save-good {
  border: 1px solid #67c23a;
  background: #67c23a;
  color: #fff;
}
.occList {
  list-style: none;
  text-align: center;
  li {
    background: #eee;
    margin-bottom: 6px;
    cursor: pointer;
    line-height: 40px;
    &:hover {
      background: #ccc;
    }
  }
}
.tag {
  margin-right: 4px;
}

.position .up-btn {
  display: inline-block;
  vertical-align: middle;
}
.position .up-btn .btn-text {
  display: inline-block;
  width: 100%;
  height: 100%;
  color: #fff;
  line-height: 32px;
}
.up-btn ::v-deep .el-upload {
  width: 98px;
  height: 40px;
  border: 0 !important;
  background-color: #409eff;
  line-height: 20px;
}
.up-btn >>> .el-button--small {
  width: 80px;
  height: 32px;
  border: 0 !important;
  background-color: #409eff;
}
.position .el-alert {
  padding: 0;
}
.pre {
  display: block;
  width: 100%;
  height: 100px;
}
.change-btn {
  padding-top: 10px;
  text-align: center;
}
</style>

